<template>
  <div>
    <!-- Todo:aqui hay que poner el componente de ecommerce para que salgan los ultimos lanzamientos con filtros-->
    <section class="bg-grey bg bg-menu">
      <b-row class="content-wrapper row">
        <b-col
          xl="5"
          md="12"
        >
          <h1 class="title-products text-white mt-4 mb-xl-3 text-xl-left">
            Outlet
          </h1>
        </b-col>
        <b-col
          xl="6"
          md="12"
          class="text-sm-center"
        >
          <p class="text-white mt-xl-4 mt-sm-1 mb-3 ">
            Encuentra las últimas tendencias y novedades en productos promocionales
          </p>
        </b-col>
      </b-row>
    </section>

    <!-- Products -->
    <section :class="itemView">
      <div
        v-for="product in products.data"
        :key="product.id"
        class=""
      >
        <div class="item-img text-center img-overlay">
          <b-link
            class="d-block m-auto"
            :to="{ name: 'product-details', params: { slug: product.slug } }"
          >
            <span
              v-if="product.discount_price"
              class="float-discont"
            >-{{ ((convertCurrencyToFloat(product.discount_price)-convertCurrencyToFloat(product.price))/convertCurrencyToFloat(product.discount_price)*100).toFixed(2) }}%</span>
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              loading="lazy"
              class="img-overlay-image"
              :src="product.thumbnails[0]"
            />
            <div class="overlay">
              <div class="text">
                Más información
              </div>
            </div>
          </b-link>
        </div>

        <!-- Product Details -->
        <div>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <div v-if="product.free_shipping_enabled">
                  <b-badge
                    v-if="product.qty_free_shipping === 1"
                    pill
                    variant="light-success"
                  >
                    Envío gratis
                  </b-badge>

                  <b-badge
                    v-else
                    variant="light-success"
                  ><!-- todo: aquí hay que ponerlo decolores segun el articulo-->
                    Envío gratis a partir de {{ product.qty_free_shipping }} paq.
                  </b-badge>

                  <!--                  <feather-icon
                    size="20"
                    icon="StarIcon"
                    class="mb-icon float-right"
                  />--><!-- todo: la estrella si le dan-->
                </div>
              </ul>
            </div>
            <div>
              <h6 class="item-name">
                <b-link
                  class="text-body"
                  :to="{ name: 'product-details', params: { slug: product.slug } }"
                >
                  {{ product.name }}
                </b-link>
              </h6>
            </div>
          </div>
          <h3 class="item-price">
            ${{ product.price }} C/U
            <strike
              v-if="product.discount_price"
              class="small"
            >
              ${{ product.discount_price }} C/U
            </strike>
          </h3>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import {
  BBadge,
  BCol,
  BImg,
  BLink,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  useShopFiltersSortingAndPagination,
  useShopRemoteData,
  useShopUi,
} from '@/views/e-commerce/e-commerce-shop/useECommerceShop'

export default {
  components: {
    BLink,
    BImg,
    BBadge,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  mounted() {
    this.removeHeaderNavbarShadow()
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    removeHeaderNavbarShadow() {
      const element = document.querySelector('.header-navbar-shadow')
      if (element) {
        element.classList.remove('header-navbar-shadow')
      }
    },
    convertCurrencyToFloat(currency) {
      const regex = /[\d.]+/g
      const matches = currency.match(regex)
      const numericString = matches.join('')
      return parseFloat(numericString)
    },
  },
  setup(props) {
    const { category } = props

    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination(category)

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()
    const fetchShopProducts = () => {
      fetchProducts({
        page: filters.value.page,
        search: filters.value.q,
        sortByPrice: sortBy.value.value,
        sortByCreatedAt: 'desc',
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRange,
        categorization: 'outlet/outlet',
        properties: (filters.value.brands) ? {
          brand: filters.value.brands,
        } : null,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.products.total
        })
    }

    fetchShopProducts()
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      fetchShopProducts,

      // useShopRemoteData
      products,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

//New Arribals
.bg-menu {
  margin-top: -45px;
  background-color: #ec2c39!important;

}
.header-navbar-shadow{
  background:none!important;
}
//New Arribals

.section-who{
  padding: 0!important;
  h3{
    color: #4A2268!important;
    font-weight: 700;
  }
  .s-img{
    position: absolute;
    bottom: -10px;
    right: -23px;
    width: 270px;
  }
  p{
    max-width: 500px;
    font-size: 18px;
    line-height: 27px;
    padding-left: 35px;
  }
}
.card-team{
  overflow: hidden;
  .card-body{
    padding: 0;
  }
  .card{
    margin-bottom: 0;
  }
}
.icon_item{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  justify-content: center;
  .item {
    width: calc(100% / 9);
    margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
    color: #1d4079;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    &:hover{
      -webkit-filter: drop-shadow(0px 0px 30px rgba(29, 64, 121));
    }
  }
  img{
    width: 65px;
    display: block;
    margin: 0 auto;
  }

}
.max-he-home{
  max-height: 253px;
}
.box-text {
  display: block;
  margin: 0 auto;
}
.btn-white-red{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #ea5455!important;
  font-weight: 700;
}

.btn-white-blue{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: rgba(29,64,121,1)!important;
  font-weight: 700;
}

.bg-red-1{
  background: rgb(142, 45, 226);
  background: linear-gradient(149deg, rgba(142, 45, 226,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}

.bg-red-2{
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}
.bg-red-3 {
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198, 1) 0%, rgba(74, 0, 224, 1) 80%);
}
.bg-red-4 {
  background: rgb(238, 9, 121);
  background: linear-gradient(149deg, rgba(238, 9, 121, 1) 0%, rgba(255, 106, 0, 1) 80%);
}
.title-products{
  color: #43413D;
  font-size: 30px ;
  text-align: center;
}
.dark-layout{
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-card-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.dot {
  width: 400px; height: 400px;
  background: radial-gradient(ellipse at center, #fff,rgba(0,0,0,.0));
}
.img-product {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}

.img-product-2 {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin: 0 auto;
}
.img-product-2-s-1{
  background-size: 180px auto;
}

.img-product-2-s-2{
  background-size: auto 190px;
}

.img-product-2-s-3{
  background-size: auto 185px;
}
.img-product-2-s-4{
  background-size: auto 173px;
}
.yourImg {
  border-radius: 50%;
}
.text-box-title{
  font-size: 30px;
}
.box-image{
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
}

.dark-layout{
  .icon-img-home{
    background-color: #d0d2d6;
  }
  .text-item{
    color: #d0d2d6!important;
  }
}
.media-body{
  p {
    margin-top: 0;
    margin-bottom: 0px!important;
  }
}
.text-item{
  font-size: 15px;
  font-weight: 700;
  color: #686868!important;

}
.media-center{
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}
.icon-img-home{
  width: 100%;
  height: 65px;
  background-color: $primary;
}

.hieleras-img{
  mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
}

.spray-img{
  mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
}

.textiles-img{
  mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
}

.star-img{
  mask: url('~@/assets/images/icos/star.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/star.svg') no-repeat center;
}

.termos-img{
  mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
}

.mochilas-img{
  mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
}

.bocinas-img{
  mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
}

.power-img{
  mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
}

.herramientas-img{
  mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
}
.exclusivos-img{
  mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
}

.otros-img{
  mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
}
.list-categories{
  .media{
    margin-bottom: 10px;
  }

}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-size: 16px;
    font-weight: 700;
  }

  .custom-col {
    flex: 0 0 calc(33.333% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;

    &:last-child {
      margin-right: 0;
    }
  }
}
#carousel-example-generic {
  width: 100vw; /* Esto establece el ancho al 100% del viewport */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.gradient-border {
  display: inline-block;
  padding: 2px; // Esto se comporta como el borde
  background: linear-gradient(to right, #F6A21B, #EC2C39, #4A2268);
  border-radius: 7px; // 2px (borde) + 5px (border-radius del botón) = 7px
  transition: background 0.3s;

  .category-button {
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    width: 130px!important;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}

.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.bg-grey{
  background-color: #f2f2f2;
}.bg-grey{
   background-color: #f2f2f2;
 }

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.img-overlay {
  .float-discont{
    z-index: 5!important;
    position: absolute;
    color: #fff;
    font-weight: 700;
    right: 10px;
    top: 10px;
    padding: 1px 6px;
    background-color: #EC2C39;
  }
  background-color: white;
  border: 1px solid #c2c2c2;
  position: relative; // necesitamos posición relativa para posicionar absolutamente el texto
  border-radius: 5px;
  margin-bottom: 15px;
  .img-overlay-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute; // esto permitirá que el texto se superponga sobre la imagen
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center; // centrar horizontalmente
    align-items: center; // centrar verticalmente
    opacity: 0; // ocultar por defecto
    background-color: rgba(0, 0, 0, 0.4); // fondo negro con 50% de transparencia
    transition: opacity 0.3s ease-in-out; // transición suave al hacer hover

    .text {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &:hover {
    .overlay {
      opacity: 1; // mostrar al hacer hover
    }
  }
}
.product-fav{
  .image-container {
    position: relative;
    width: auto; // O define un ancho específico si lo necesitas
    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .overlay-content {
      text-align: left;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 20px;
      padding-left: 15px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
      .category-text {
        color: white;
        font-size: 2em;
        margin-bottom: 10px;
      }
      .btn {
        max-width: 160px;
      }
    }
  }
}

.blockquote{
  background-color: #fff;
  padding: 20px 30px;
  font-size: 15px;
  height: 100%;
  p{
    margin-bottom: 0px;
    margin-top: 5px;
    color: #940C4A!important;
  }
  .quote-mark{
    color: #940C4A;
    line-height: 0px;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .float-points{
    display: none;
  }
  .cr-values{
    display: none!important;
  }
  .catogory{
    h2{
      color: #43413D;
      font-size: 25px ;
    }
  }
}

@media (min-width:1200px) and (max-width: 1480px) {
  .img-product{
    width: 245px!important;
    height: 245px!important;
    background-size: 120px!important;
  }
}
@media (max-width:991px){
  .gradient-border {
    margin-bottom: 20px;
  }
  .mb-box-home{
    margin-bottom: 30px;
  }
  .icon_item {
    .item{
      width: calc(100% / 5);
    }
  }
}

@media (max-width:1500px){
  .container-who{
    padding: 18px 70px;
  }
  .section-who{

    img{
      display: none;
    }
    h3{
      margin-top: 30px;
    }
    p{
      padding: 0;
      max-width: none;
    }
  }
}
@media (max-width:576px){
  .icon_item {
    .item{
      width: calc(100% / 3);
    }
  }
}

</style>
